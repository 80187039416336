/* styles.css */
.e-multiselect.e-input-group .e-multi-select-wrapper {
    background-color: #f3f4f6;
    padding-top: 0.4rem;
    padding-left: 0.4rem;
    padding-bottom: 0.4rem;
    /* width: 30rem; */
    height: 2rem;
    display: flex;
    align-items: center;
}

.e-btn.e-flat.e-primary, .e-css.e-btn.e-flat.e-primary {
    background-color: rgba(0, 0, 0, 0);
    border-color: rgba(0, 0, 0, 0);
    color: #e3ac16;
}