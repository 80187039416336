/* styles.css */
.e-multiselect.e-input-group .e-multi-select-wrapper {
  background-color: #f3f4f6;
  padding-top: 0.4rem;
  padding-left: 0.4rem;
  padding-bottom: 0.4rem;
  /* width: 30rem; */
  height: 2.5rem;
  display: flex;
  align-items: center;
  border-width: 0.1rem;
  border-radius: 0.4rem;
  border-color: #000000;
}

.e-btn.e-flat.e-primary,
.e-css.e-btn.e-flat.e-primary {
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
  color: #e3ac16;
}

.e-multiselect.e-input-group.e-control-wrapper.e-input-focus::before,
.e-multiselect.e-input-group.e-control-wrapper.e-input-focus::after {
  background: #e3ac16;
}

.e-popup .e-checkbox-wrapper .e-frame.e-check,
.e-popup .e-checkbox-wrapper:hover .e-frame.e-check {
  background-color: #e3ac16;
  color: white;
}

.e-ddl.e-input-group.e-control-wrapper .e-input,
.e-input-group .e-control-wrapper .e-ddl {
  border-color: #000000;
  border-width: 0.05rem;
  border-radius: 0.4rem;
  padding-left: 0.5rem;
  height: 1.9rem;
}

.e-ddl.e-input-group .e-input-group-icon,
.e-ddl.e-input-group.e-control-wrapper .e-input-group-icon:hover {
  color: #e3ac16;
  font-size: 13px;
}

.e-ddl.e-input-group.e-control-wrapper.e-input-focus::before,
.e-ddl.e-input-group.e-control-wrapper.e-input-focus::after {
  border-color: #000000;
  border-width: 0.1rem;
}
